@font-face {
  font-family: 'icomoon';
  src: url('./../../assets/icomoon/fonts/icomoon.ttf?jth9c3') format('truetype'),
    url('./../../assets/icomoon/fonts/icomoon.woff?jth9c3') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &[data-colored="true"]:before {
    color: inherit;
  }
}

.icon-mailbox_line:before {
  content: "\e900";
  color: #09244b;
}
.icon-phone_call_line:before {
  content: "\e901";
  color: #09244b;
}
