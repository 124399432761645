.card-background {
  @apply relative rounded-lg;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  padding: theme('spacing.9');
  min-height: 24rem;
  z-index: 0;

  &__image {
    @apply absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  &__image:after {
    content: '';
    background: rgb(0,0,0);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &__image img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__eyebrow {
    @apply text-lg font-medium text-white;
    margin-bottom: theme('spacing.3');
    z-index: 1;
  }

  &__heading {
    @apply text-h3 text-white;
    font-weight: theme('fontWeight.semibold');
    margin-bottom: theme('spacing.5');
    z-index: 1;
  }

  &__action {
    z-index: 1;
  }

  &__action > .btn {
    text-transform: capitalize;
  }
}
