.menu {
  @apply px-3;
}

.page-section,
.container {
  @apply px-5;
}

@media (min-width: 768px) {
  .page-section,
  .container {
    @apply px-8;
  }
}

@media (min-width: 1024px) {
  .container {
    @apply px-8;
  }
}

@media (min-width: 1280px) {
  .container {
    @apply px-8;

    &-top &__items {
      @apply flex space-x-8 py-2;
    }

    &__toggle {
      @apply hidden;
    }

    &-top &__cta {
      @apply relative z-10 ml-4 flex items-center;
    }

    &-panel {
      @apply hidden;
    }
  }
}
