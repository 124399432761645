@tailwind base;
@tailwind components;
@tailwind utilities;

@import './variables';
@import './resets';

@import './base-elements/button';

/* Components */
/* @import './components/banner'; */
@import './components/cardBackground';
@import './components/cardSingleItem';
@import './components/container';
@import './components/form';
@import './components/labeledItem';
@import './components/navbar';
@import './components/pageSection';
@import './components/selectMenu';

/* Layouts */
@import './layouts/generalLayout';
@import './layouts/toursDetails';

@import './utility-classes/iconography';

.i18n-change {
  img {
    width: 30px;
  }
}
