.menu {
  width: 100%;
  z-index: 100;

  // Menu - Locations
  &-banner {
    @apply top-7 transition-all duration-500;
    margin-bottom: calc(-1 * var(--menu-height) - theme('spacing.[0.5]'));
    position: sticky;
    transition-property: background-color, border, box-shadow, top, color, padding;
  }

  &-banner.is--scrolling {
    @apply top-7;
    border-color: rgba(255,255,255,0);
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, .06);
  }

  &-primary {
    @apply mt-7 sticky transition-all duration-500;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, .06);
    border-color: rgba(255,255,255,0);
  }

  &-primary,
  &-banner.is--scrolling {
    @apply top-7;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, .06);
  }

  // Menu - Top Container
  &-top {
    @apply rounded-lg p-7;
  }

  &-banner &-top {
    @apply px-7;
    transition-property: inherit;
    transition-duration: inherit;
    transition-timing-function: inherit;
  }

  &-banner.is--scrolling &-top {
    @apply px-7;
  }

  &-primary > &-top,
  &-banner > &-top {
    @apply bg-white;
  }

  &-primary:has(.menu-panel) > &-top,
  &-banner:has(.menu-panel) > &-top {
    @apply rounded-b-none;
  }

  // Menu - Container
  &-container {
    @apply relative flex justify-between;
  }

  &__logo {
    @apply relative z-10 flex;
  }

  // Menu - Items Container
  &-top &__items {
    @apply font-semibold flex-1 items-center hidden justify-start px-2 ml-10 relative z-0;
  }

  &-banner &-top &__items {
    @apply text-white;
  }

  // Menu - Items
  &-top &__item,
  &-banner &-top &__item {
    @apply duration-300 transition-colors text-grey;

    &:hover,
    &.active {
      @apply text-orange;
    }
  }

  // Menu - Toggle
  &__toggle {
    @apply relative z-10 flex gap-x-3 items-center;
  }

  &__toggle > button {
    @apply bg-sky-blue inline-flex items-center justify-center rounded-full p-2 text-blue;

    &:hover {
      @apply bg-lighter-blue text-dark-blue;
    }

    &:focus {
      @apply bg-lighter-blue outline-none ring-2 ring-inset ring-light-blue text-dark-blue;
    }
  }

  // Menu - Cta
  &-top &__cta {
    @apply hidden;
  }

  &__cta .btn {
    @apply px-4;
  }

  &-banner &__cta .btn.btn-secondary {
    background-color: theme('colors.lighter-blue');
    color: theme('colors.blue');

    &:hover {
      background-color: theme('colors.light-blue');
      color: theme('colors.dark-blue');
    }
  }

  // Menu - Panel
  &-panel {
    @apply absolute bg-white left-3 bottom-0 rounded-b-lg;
    transform: translateY(100%);
    width: calc(100% - (theme('spacing.3') * 2));
  }

  &-panel &__items {
    @apply border-t border-sky-blue py-4 px-7;
  }

  &-panel &__cta {
    @apply border-t border-sky-blue flex items-center justify-between px-7 py-4;
  }

  &-footer {
    position: initial;
  }
}

@media (min-width: 640px) {
  .menu {
    &-top &__items {
      @apply inset-0;
    }
  }
}

@media (min-width: 768px) {
  
}

@media (min-width: 1024px) {
  
}

@media (min-width: 1280px) {
  .menu {
    

    &-top &__items {
      @apply flex space-x-8 py-2;
    }

    &__toggle {
      @apply hidden;
    }

    &-top &__cta {
      @apply relative z-10 ml-4 flex items-center;
    }

    &-panel {
      @apply hidden;
    }
  }
}
