.container {
  @apply w-full;
}

@media (min-width: 768px) {
  
}

@media (min-width: 1024px) {
  
}

@media (min-width: 1280px) {
  
}
