.page-section {
  padding: theme('spacing.20') 0;

  &__container {
    align-items: center;
    flex-direction: column;
    display: flex;
  }

  &__eyebrow {
    @apply text-orange text-lg font-semibold;
    margin-bottom: theme('spacing.1');
    text-align: center;
  }

  &__heading {
    @apply w-10/12 text-5xl font-bold;
    color: var(--color-black);
    margin-bottom: theme('spacing.[3]');
    text-align: center;
  }

  &__description {
    @apply w-10/12;
    color: var(--color-grey);
    margin-bottom: theme('spacing.6');
    text-align: center;
  }

  &__content {
    @apply mb-8 w-full;
  }

  &__actions {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: theme('spacing.4');
  }
}

@media (min-width: 640px) {
  
}

@media (min-width: 768px) {
  .page-section {
    &__heading {
      @apply w-6/12;
    }

    &__actions {
      align-items: center;
      column-gap: theme('spacing.5');
      flex-direction: row;
      justify-content: center;
    }
  }
}

@media (min-width: 1024px) {
  
}

@media (min-width: 1280px) {
  .page-section {
    

    &__description {
      @apply max-w-3xl;
    }
  }
}
