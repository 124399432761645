.tours-details {
  @apply flex flex-col items-start gap-y-8;

  & > &__content {
    @apply w-full;
    order: 1;
  }

  & > &__form {
    @apply bg-sky-blue p-8 rounded-xl w-full;
    order: 0;
  }

  // Page Thumbnail
  &__content > &__thumbnail {
    @apply mb-9;
    height: 26rem;
  }

  &__content > &__thumbnail img {
    @apply rounded-lg;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  // Page Title
  &__content > &__title {
    @apply text-h3 mb-4;
  }

  // Tour Sections
  &__sections {
    display: flex;
    flex-direction: column;
    row-gap: theme('spacing.6');
  }

  // Tour Sections - Section
  &__sections > &__section {
    display: flex;
    flex-direction: column;
    padding-bottom: theme('spacing.6');
    row-gap: theme('spacing.[2.5]');

    &:not(:last-child) {
      border-bottom: theme('spacing.1') solid theme('colors.sky-blue');
    }
  }

  &__sections > &__section > &__title {
    @apply text-h5;
  }

  &__sections > &__section > &__content {
    @apply text-grey;
  }

  // Page Right Title
  &__form > &__title {
    @apply text-h3 mb-6;
  }

  &__form .form > .form-consent {
    @apply mx-0 px-0 text-left;
  }

  &__form .form > .form-actions {
    @apply mx-0;
  }
}

// lg
@media (min-width: 1024px) {
  .tours-details {
    @apply flex-row gap-y-0 gap-x-8;
    & > &__content {
      @apply w-7/12;
      order: 0;
    }

    & > &__form {
      @apply w-5/12;
      order: 1;
    }
  }
}
