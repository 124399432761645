.form {
  display: flex;
  flex-direction: column;

  &--bordered {
    @apply rounded-lg;
  }

  // Form Group
  &-group {
    display: flex;
    flex-direction: column;
    margin-left: theme('spacing.px');
  }

  &-group + &-group {
    margin-top: calc(-1 * theme('spacing.px'));
  }

  // Form Element
  &-group &__element {
    @apply w-full;
  }

  &-group &__element + &__element {
    margin-top: calc(-1 * theme('spacing.px'));
  }

  &--bordered &-group &__element {
    flex: 1;
    // overflow: hidden;
    position: relative;
  }

  // Form Label
  &--bordered &__element &__label {
    align-items: baseline;
    color: theme('colors.grey');
    column-gap: theme('spacing.[1.5]');
    display: flex;
    font-size: theme('spacing.[3.5]');
    font-weight: theme('fontWeight.semibold');
    left: theme('spacing.[2.5]');
    line-height: theme('spacing.6');
    position: absolute;
    top: theme('spacing.[2.5]');
    z-index: 1;
  }

  &--bordered &__element &__label &__error {
    @apply text-xs;
    color: theme('colors.error');
  }

  // Form Input
  &--bordered &-group:first-child &__element:first-child &__input,
  &--bordered &-group:first-child &__element:first-child &__input:focus + &__focus {
    @apply rounded-t-lg;
  }

  &--bordered &-group:last-child &__element:last-child &__input,
  &--bordered &-group:last-child &__element:last-child &__input:focus + &__focus {
    @apply rounded-b-lg;
  }

  // Form Input - Different Types
  &--bordered &__element > &__input,
  &--bordered &__element > .react-datepicker-wrapper input  {
    border: theme('spacing.px') solid theme('colors.lighter-grey');
    padding: theme('spacing.[1.5]') theme('spacing.[2.5]');
    padding-top: calc(theme('spacing.6') + theme('spacing.[2.5]'));
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &--bordered &__element > select.form__input {
    background-image: url('./../../assets/icons/down_small_line.png');
    background-position: 96% 85%;
    background-repeat: no-repeat;
    appearance: none;
    height: 66px;

    &:active {
      background-image: url('./../../assets/icons/up_small_line.png');
    }
  }

  &--bordered &__element > .react-datepicker-wrapper input {
    cursor: pointer;
  }

  &__element .react-datepicker-wrapper {
    width: 100%;
  }

  &--bordered &__element textarea.form__input {
    resize: none;
    vertical-align: top;
  }

  &--bordered &__element &__input.react-tel-input {
    position: relative;
    height: 100%;
  }

  &--bordered &__element &__input.react-tel-input &-control {
    border: unset;
    border-radius: unset;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: theme('spacing.4');
    font-weight: theme('fontWeight.medium');
    left: 0;
    line-height: theme('lineHeight.6');
    height: 100%;
    padding: theme('spacing.[1.5]') theme('spacing.[2.5]');
    padding-left: calc(theme('spacing.[2.5]') + theme('spacing.8'));
    padding-top: calc(theme('spacing.6') + theme('spacing.[2.5]'));
    position: absolute;
    top: 0;
    width: 100%
  }

  &--bordered &__element &__input.react-tel-input .flag-dropdown {
    border: unset;
    border-radius: unset;
    bottom: unset;
    top: calc(theme('spacing.6') + theme('spacing.[2.5]') + theme('spacing.px'));
    transform: translateY(50%) scale(1.1);
    transform-origin: left center;
  }

  &--bordered &__element &__input.react-tel-input .selected-flag {
    border-radius: unset;
    padding: unset;
    width: unset;
  }

  &--bordered &__element &__input.react-tel-input .selected-flag .flag {
    position: static;
    margin-top: theme('spacing.[0.5]');
  }

  &--bordered &__element &__input.react-tel-input .selected-flag .arrow {
    border-radius: theme('spacing.1');
    transform: translateY(-50%);
  }

  // Form Input - Focused
  &--bordered &__element &__input + &__focus {
    @apply ease-in-out;
    border: theme('spacing.px') solid theme('colors.transparent');
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    transition-property: border;
    transition-duration: 300ms;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &--bordered &__element &__input:focus + &__focus {
    border: theme('spacing.px') solid theme('colors.orange');
  }

  // Form Input Focus
  &__element &__input &__focus {
    pointer-events: none;
  }

  // Form Consent
  &-consent {
    @apply px-9 text-center text-xs text-grey;
    margin: auto;
    margin-top: theme('spacing.6');
  }
  // Form Actions
  &-actions {
    align-items: center;
    column-gap: theme('spacing.1');
    display: flex;
    margin: auto;
    margin-top: theme('spacing.7');
  }

  .react-datepicker-popper {
    z-index: 2;
  }
}


@media (min-width: 640px) {
  .form {
    &-group {
      flex-direction: row;
    }

    &--bordered &-group &__element + &__element {
      margin-left: calc(-1 * theme('spacing.px'));
      margin-top: unset;
    }

    &--bordered &-group:first-child &__element:first-child &__input,
    &--bordered &-group:first-child &__element:first-child &__input:focus + &__focus {
      @apply rounded-tl-lg rounded-tr-none;
    }

    &--bordered &-group:first-child &__element:last-child &__input,
    &--bordered &-group:first-child &__element:last-child &__input:focus + &__focus {
      @apply rounded-tr-lg;
    }

    &--bordered &-group:last-child &__element:first-child &__input,
    &--bordered &-group:last-child &__element:first-child &__input:focus + &__focus {
      @apply rounded-bl-lg;
    }

    &--bordered &-group:last-child &__element:last-child &__input,
    &--bordered &-group:last-child &__element:last-child &__input:focus + &__focus {
      @apply rounded-br-lg;
    }
  }
}
