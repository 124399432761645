.btn {
  @apply bg-blue ease-in-out font-semibold rounded-full text-base text-white;
  display: inline-flex;
  padding: theme('spacing.[2]') theme('spacing.9');
  transition-property: background, background-color, box-shadow;
  transition-duration: 300ms;

  &:hover {
    @apply bg-dark-blue;
    box-shadow: 0 0 5px 4px rgba(0, 0, 0, .06);
  }

  &-secondary {
    @apply bg-lighter-blue text-blue;

    &:hover {
      @apply bg-light-blue text-dark-blue;
    }
  }

  &-link {
    @apply bg-transparent rounded-none;
    padding: 0;
    text-decoration: underline;
  }
}
