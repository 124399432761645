.card-single-item {
  border-radius: theme('spacing.2');
  box-shadow: 0 0 20px -6px rgba(0, 0, 0, .1);
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;

  &.hidden {
    display: none;
  }

  &__header {
    height: theme('maxHeight.64');
    max-height: theme('maxHeight.64');
  }

  &__header img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__content {
    background-color: theme('colors.white');
    padding: theme('spacing.8');
  }

  &__content &__heading > * {
    @apply text-2xl font-bold text-ellipsis overflow-hidden whitespace-nowrap;
  }

  &__content &__heading + &__description {
    @apply mt-4;
  }

  &__action {
    @apply bg-lighter-blue ease-in-out font-semibold mt-4 rounded-full text-base text-blue;
    display: inline-flex;
    padding: theme('spacing.[2]') theme('spacing.9');
    transition-property: background, background-color, box-shadow;
    transition-duration: 300ms;

    &:hover {
      @apply bg-light-blue text-dark-blue;
    }
  }
}
