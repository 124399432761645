.select-menu {
  &__label {
    @apply block text-sm font-medium leading-6 text-grey;
  }

  &__wrapper {
    @apply relative;
  }

  &__display {
    @apply relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-grey shadow-sm ring-2 ring-inset ring-sky-blue h-10;

    &:focus {
      @apply outline-none ring-2 ring-light-blue;
    }
  }

  &__items {
    @apply absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5;

    &:focus {
      @apply outline-none;
    }
  }
}

// sm - tablet
@media (min-width: 640px) {
  .select-menu {
    &__display {
      @apply text-sm leading-6;
    }

    &__items {
      @apply text-sm;
    }
  }
}
